<template>
  <div>
    <div class="row">
      <div class="flex md1"></div>
      <div class="flex md10">
        <va-card>
          <va-card-title class="d-flex justify--space-between">
            <div>
              <h3 class="mb-0">Liste des réservations pour les voyages à venir</h3>
            </div>
          </va-card-title>
          <va-card-content>
            <va-modal ref="reservationModal" v-model="reservationModal" size="large" hide-default-actions>
              <div v-if="currentReservation">
                <h4>
                  {{ currentReservation.trip.tour.title }}
                </h4>
                <ul>
                  <li>Numéro de réservation: <strong> {{ currentReservation.id }} </strong></li>
                  <li>Date de Réservation: <strong>{{ currentReservation.reservation_date }}</strong></li>
                  <li>Status: <strong> {{ currentReservation.status }} </strong></li>
                  <li>Agence de Voyage: <strong>{{ currentReservation.trip.tour.operator.name }}</strong> </li>
                  <li>Voyage Organisé: <strong>{{ currentReservation.trip.tour.title }}</strong></li>
                  <li>Durée: <strong>{{currentReservation.days_count }} jours</strong> </li>
                  <li>Date de Départ: <strong>{{ currentReservation.trip.start_date }}</strong> </li>
                  <li>Date de Retour: <strong>{{ currentReservation.trip.end_date }}</strong> </li>
                  <li>Réservé par: <strong>{{ currentReservation.user.name }} </strong></li>
                  <li>Numéro de Téléphone: <strong>{{ currentReservation.user.phone }} </strong></li>
                  <li>Adresse Email: <strong>{{ currentReservation.user.email }} </strong></li>
                </ul>
                <div class="va-table-responsive">
                  <table class="va-table">
                    <tbody>
                      <tr>
                        <th scope="row" class="text-body-1">
                          Type
                        </th>
                        <th scope="row" class="text-body-1">
                          Nom
                        </th>
                        <th scope="row" class="text-body-1">
                          Téléphone
                        </th>
                        <th scope="row" class="text-body-1">
                          Email
                        </th>
                        <th scope="row" class="text-body-1">
                          Prix
                        </th>
                      </tr>
                      <tr v-for="traveler in currentReservation.reservation_travelers" :key="traveler.id">
                        <td>Voyageur</td>
                        <td>{{ traveler.name }}</td>
                        <td>{{ traveler.phone ? traveler.phone : '-' }}</td>
                        <td>{{ traveler.email ? traveler.email : '-' }}</td>
                        <td>{{ traveler.price }} Dhs</td>
                      </tr>
                      <tr v-for="supplement in currentReservation.reservation_items" :key="supplement.item_id">
                        <td>Supplément</td>
                        <td colspan="3">{{ supplement.item.title }}</td>
                        <td>{{ supplement.price }} Dhs</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th scope="row" class="text-h6" colspan="4">
                          Total
                        </th>
                        <td>
                          <span class="text-h1">
                            {{ currentReservation.total_price }} Dhs
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div v-else>
                <va-icon
                  class="mr-2"
                  name="loop"
                  spin="counter-clockwise"
                />
              </div>
              <template #footer>
                <div v-if="currentReservation">
                  <va-button color="danger" class="mr-4 mb-2" @click="cancelReservation" :loading="cancellationLoading">
                    Annuler
                  </va-button>
                  <va-button color="success" class="mr-4 mb-2" @click="confirmReservation" :loading="confirmationLoading">
                    Confirmer
                  </va-button>
                </div>
              </template>
            </va-modal>
            <va-input class="flex mb-2 md6" placeholder="Filter..." v-model="filter"/>
            <va-data-table :items="reservations" :columns="columns" :hoverable="true" :filter="filter">
              <template #cell(created_at)="{value}"> {{ formatDate(value) }}</template>
              <template #cell(total_price)="{value}"> {{ value }} Dhs</template>
              <template #cell(actions)="{ rowIndex }">
                <va-button flat icon="eye" @click="openModalToViewReservation(rowIndex)" />
              </template>
            </va-data-table>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/fr";

export default {
  name: "ReservationList",
  data() {
    return {
      filter: "",
      isTableLoading: false,
      reservations: [],
      reservationModal: false,
      confirmationLoading: false,
      cancellationLoading: false,
      columns: [
        { key: 'id', label: 'ID' },
        { key: 'tour_title', label: "Voyage" },
        { key: "reservation_travelers_count", label: "Voyageurs", sortable: true },
        { key: "total_price", label: "Prix", sortable: true },
        { key: "created_at", name: 'created_at', label: "Date de création", sortable: true },
        { key: "status", label: "Status" },
        { key: 'actions', label: 'Voir' },
      ],
      currentReservationId: null,
      currentReservation: null,
    }
  },
  async created() {
    this.isTableLoading = true;
    const { data } = await this.axios.get("/api/admin/reservations");
    this.reservations = data;
    this.isTableLoading = false;
  },
  methods: {
    async openModalToViewReservation(rowIndex) {
      this.currentReservation = null
      this.$refs.reservationModal.show()
      this.currentReservationId = this.reservations[rowIndex].id;
      const { data } = await this.axios.get(`/api/reservation/${this.currentReservationId}`)
      this.currentReservation = data
    },
    async cancelReservation() {
      try {
        const cancelReason = prompt("Veuillez saisir la raison de l'annulation")
        if (cancelReason) {
          this.cancellationLoading = true
          await this.axios.post(`/api/reservation/${this.currentReservationId}/cancel`, {
            'cancel_reason': cancelReason
          })
          this.$vaToast.init({
            color: "success",
            message: "Annulation effectuée avec succès",
          })
          this.$refs.reservationModal.hide()
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.cancellationLoading = false
      }
    },
    async confirmReservation() {
      try {
        this.confirmationLoading = true
        await this.axios.post(`/api/reservation/${this.currentReservationId}/confirm`)
        this.$refs.reservationModal.hide()
        this.$vaToast.init({
          color: "success",
          message: "Réservation confirmée avec succès"
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.confirmationLoading = false
      }
    },
    formatDate(value) {
      const date = moment(value)
      if (date.isSame(moment(), 'day')) {
        return 'Aujourd\'hui'
      } else if (date.isSame(moment().add(1, 'day'), 'day')) {
        return 'Demain'
      } else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
        return 'Hier'
      } else {
        return date.format('dddd, DD MMMM YYYY')
      }
    }
  }
}
</script>

<style scoped>

</style>
